import React from 'react';
import Button from '../Components/Button';
import styles from './SubscribeSuccess.module.scss';
import ExitIcon from '../Components/assets/exit.svg';
import FancyImage from '../Components/FancyImage';
import MaralImage from '../assets/maral.png';

const SubscribeSuccessModal: React.FC = () => {
    return (
        <div className={styles["subscribe-thanks-container"]}>
            <div className={styles["subscribe-thanks-content"]}>
                <div className={styles["thanks-message"]}>
                    <FancyImage classNames={`${styles["maral-floating"]} dark-loader`} src={MaralImage} alt="Kayhan Space's mascot, Maral!" />
                    <h3>You're subscribed!</h3>
                    <p>We look forward to keeping you updated.</p>
                </div>
                <Button classNames={styles["subscribe-dismiss"]} text="Dismiss" isLink href="/#" renderIcon={<ExitIcon />} />
            </div>
        </div>
    );
}

export default SubscribeSuccessModal;
