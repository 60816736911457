import React from "react";
import { PageSection } from "../@types/PageSection";
import throttle from "lodash/throttle";
import DropdownIcon from './assets/list--dropdown.svg';
import buttonStyles from './Button.module.scss'

/* function convertRemToPixels(rem: number): number {
    return rem * parseFloat(window.getComputedStyle(document.documentElement).fontSize);
} */

const leftOffset = 32;
const overrideOffset = 150;

interface IProps {
    setActiveNo: Function;
    activeNo: number;
    pageSections: PageSection[];
    elRefs: React.RefObject<HTMLDivElement>[];
    //newsHeaderRef: React.RefObject<HTMLDivElement>;
    activationRef: React.RefObject<HTMLDivElement> | null;
}

interface NavStyles {
    top?: string;
    left?: string
}

const mobileBreak = 1024;
const maxWidth = 1392;

const PageNav: React.FC<IProps> = ({ setActiveNo, activeNo, pageSections, elRefs, /*newsHeaderRef,*/ activationRef }) => {

    const [prevWidth, setPrevWidth] = React.useState(0);
    const [scrollProgress, setScrollProgress] = React.useState(0);
    const [navClassNames, setNavClassNames] = React.useState('');
    const [mobileNavClassNames, setMobileNavClassNames] = React.useState('');
    const mobileNavRef = React.useRef<HTMLDivElement>(null);
    const sectionNavRef = React.useRef<HTMLDivElement>(null);
    const [navTopValue, setNavTopValue] = React.useState(0);
    const [navLeftValue, setNavLeftValue] = React.useState(0);
    const [navStyles, setNavStyles] = React.useState({});
    const [breaks, setBreaks] = React.useState<(number)[]>([]);

    const handleScroll = throttle(() => {
        const overrideElement = elRefs[elRefs.length - 2].current;
        const lastElement = elRefs[elRefs.length - 1].current;
        const firstElement = elRefs[0].current;
        if (activationRef
                && activationRef.current
                && /*newsHeaderRef.current &&*/ mobileNavRef.current
                && sectionNavRef.current
                && overrideElement
                && lastElement
                && firstElement) {
            
            let classNameStr = '';
            const topElementOffset = prevWidth > mobileBreak ? activationRef.current.offsetHeight - 160 : activationRef.current.offsetHeight;
            const currentScrollPos = window.pageYOffset;
            if ((lastElement.getBoundingClientRect().top - overrideOffset) <= 0) {
                setNavTopValue(lastElement.offsetTop + 58);
                classNameStr += ' override';
            } else {
                if (currentScrollPos >= topElementOffset) classNameStr += ' fixed';
            }
            setNavClassNames(classNameStr);

            if (currentScrollPos >= activationRef.current.offsetHeight - Math.ceil(mobileNavRef.current?.offsetHeight / 2)) {
                setMobileNavClassNames(' fixed');
            } else {
                setMobileNavClassNames('');
            }
            const activeItems = breaks.filter((val) => val <= (currentScrollPos - topElementOffset + (window.innerHeight / 1.25)));
            const activeNo = activeItems.length;
            setActiveNo(activeNo - 1);
            setScrollProgress((activeNo / breaks.length) * 100);
            setBreaks(elRefs.map(elRef => {
                if (elRef.current) {
                    return (elRef.current.offsetTop)
                }
                return -1
            }));
        }
    }, 250);

    const handleResize = throttle(() => {
        const width = window.innerWidth;
        const calcOffset = (width - maxWidth) / 2;
        const offset = calcOffset > leftOffset ? calcOffset : leftOffset;
        setNavLeftValue(width >= maxWidth ? offset : leftOffset);
        setPrevWidth(width);
    }, 250);

    React.useEffect(() => {
        let newNavStyles: NavStyles = {
            top: '',
            left: ''
        };
        if (navTopValue) {
            newNavStyles.top = `${navTopValue}px`;
        }
        if (navLeftValue) {
            newNavStyles.left = `${navLeftValue}px`;
        }
        if (navClassNames.indexOf('override') === -1) {
            delete newNavStyles.top;
        }
        setNavStyles(newNavStyles);
    }, [navTopValue, navLeftValue, navClassNames])

    function scrollToAnchor(aId: string) {
        pageSections.forEach((pageSection, i) => {
            if (pageSection.anchorId === aId) {
                const offset = window.innerHeight - (window.innerHeight / 1.25);
                const y = (Math.floor((elRefs[i].current!.getBoundingClientRect().top) + (window.pageYOffset) - offset));
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        });
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        scrollToAnchor(e.target.value);
    }

    const handleOnClick = (aId: string) => {
        scrollToAnchor(aId);
    }

    React.useEffect(() => {
        handleScroll();
        handleResize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        }
    }, [handleResize, handleScroll]);
    
    const className = `${buttonStyles.button} ${buttonStyles.white}`;
    return (
        <>
            <nav className={`page-section-nav ${navClassNames}`} style={navStyles} ref={sectionNavRef}>
                <ul className="page-section-nav-list">
                    {
                        pageSections.map((section: PageSection, i: number) => {
                            return (
                                <li key={`section-nav-${i}`}>
                                    <button
                                        aria-label={`Scroll to ${section.title} section`}
                                        type="button"
                                        className={i === activeNo ? 'active' : ''}
                                        onClick={() => handleOnClick(section.anchorId)}
                                    >
                                        <span>{String(i).padStart(2, '0')}</span>
                                    </button>
                                    <span className="title">{section.title}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </nav>
            <nav className={`select-container page-section-nav-mobile${mobileNavClassNames}`} ref={mobileNavRef}>
                <DropdownIcon />
                <select name="page-section-nav-mobile-menu" className="select" title="Mobile navigation menu" onChange={handleOnChange} value={pageSections[activeNo] ? pageSections[activeNo].anchorId : ''}>
                    {
                        pageSections.map((section: PageSection, i: number) => {
                            return <option key={`section-nav-mobile-${i}`} value={section.anchorId}>{`${String(i).padStart(2, '0')}: ${section.title}`}</option>
                        })
                    }
                </select>
                <div className={`page-section-nav-mobile-progress-bar`} style={{ width: `${scrollProgress}%` }} />
            </nav>
        </>
    )
};

export default PageNav;

