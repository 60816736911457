import React from "react";
import { SectionProps } from "../../@types/Section";
import Splitter from "../../Components/Splitter";
import FadeIn from "../../Components/FadeIn";
import Typer from "../../Components/Typer";
import styles from "./Quotes.module.scss";

interface Props extends SectionProps {
    data: any
}

const HomeQuotes = React.forwardRef<HTMLElement, Props>(function HomeQuotes(props, ref) {
    const intervalId1: { current: NodeJS.Timeout | null } = React.useRef(null);
    const intervalId2: { current: NodeJS.Timeout | null } = React.useRef(null);
    const intervalId3: { current: NodeJS.Timeout | null } = React.useRef(null);
    const intervalId4: { current: NodeJS.Timeout | null } = React.useRef(null);
    const intervalId5: { current: NodeJS.Timeout | null } = React.useRef(null);
    const [part, setPart] = React.useState(0);
    const [partIdx, setPartIdx] = React.useState(0);
    const [text, setText] = React.useState('');
    const [isDeleting, setIsDeleting] = React.useState(false);
    const hasRunOnce = React.useRef(false);
    const [hideTitle, setHideTitle] = React.useState(true);
    const quoteGhosts = React.useRef(props.data ? props.data.map((quoteObj: any, i: number) => quoteObj.Quote && quoteObj.Name && quoteObj.JobTitle ? <div key={`quote-ghost-${i}`} className={styles["ghost"]}>{quoteObj.Quote}</div> : null) : []);
    const nameGhosts = React.useRef(props.data ? props.data.map((quoteObj: any, i: number) => quoteObj.Quote && quoteObj.Name && quoteObj.JobTitle ? <div key={`name-ghost-${i}`} className={styles["ghost"]}>{quoteObj.Name}</div> : null) : []);
    const titleGhosts = React.useRef(props.data ? props.data.map((quoteObj: any, i: number) => quoteObj.Quote && quoteObj.Name && quoteObj.JobTitle ? <div key={`title-ghost-${i}`} className={styles["ghost"]}>{quoteObj.JobTitle}</div> : null) : []);

    const typeQuote = React.useCallback(() => {
        // Get substring with 1 characater added
        const newPartIdx = partIdx + 1;
        if (props.data && props.data[part]) {
            const text = props.data[part].Quote.substring(0, newPartIdx);
            setText(text);
            setPartIdx(newPartIdx);
            if (text === props.data[part].Quote) {
                setHideTitle(false);
                const timeoutSession1 = setTimeout(() => {
                    setIsDeleting(true);
                }, 15000);
                intervalId1.current = timeoutSession1;
            }
        }
    }, [partIdx, part])

    const deleteQuote = React.useCallback(() => {
        // Get substring with 1 characater deleted
        if (partIdx !== 0) {
            setText('');
            setPartIdx(0);
        }
    }, [partIdx])

    React.useEffect(() => {
        if (props.data && props.data[part]) {
            if (!hasRunOnce.current) {
                const timeoutSession2 = setTimeout(() => {
                    hasRunOnce.current = true;
                    typeQuote();
                }, 500);
                intervalId2.current = timeoutSession2;
            }

            if (text.length !== props.data[part].Quote.length && !isDeleting && hasRunOnce.current) {
                const timeoutSession3 = setTimeout(() => {
                    typeQuote()
                }, 17);
                intervalId3.current = timeoutSession3;
            } else if (isDeleting && text.length) {
                const timeoutSession4 = setTimeout(() => {
                    deleteQuote()
                }, 2000);
                intervalId4.current = timeoutSession4;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partIdx, typeQuote, deleteQuote, isDeleting, part]);

    React.useEffect(() => {
        if (!text.length && isDeleting) {
            setHideTitle(true);
            const timeoutSession5 = setTimeout(() => {
                let nextPart = part + 1;
                if (nextPart > props.data.length - 1) nextPart = 0;
                setIsDeleting(false);
                setPart(nextPart);
            }, 3000);
            intervalId5.current = timeoutSession5;
        }
    }, [text, isDeleting, intervalId5, part]);

    React.useEffect(() => {
        return () => {
            if (intervalId1.current) clearTimeout(intervalId1.current as NodeJS.Timeout);
            if (intervalId2.current) clearTimeout(intervalId2.current as NodeJS.Timeout);
            if (intervalId3.current) clearTimeout(intervalId3.current as NodeJS.Timeout);
            if (intervalId4.current) clearTimeout(intervalId4.current as NodeJS.Timeout);
            if (intervalId5.current) clearTimeout(intervalId5.current as NodeJS.Timeout);
        }
    }, []);

    return (
        <section id="quotes" ref={ref}>
            <div className="content-container">
                <Splitter vizTrigger isThick text={String(props.sectionNo).padStart(2, '0')} />
            </div>
            <div className="content-container padded-left">
                <div className="_2-col-titled">
                    <div className="col">
                        <h3 className="h2">
                            <Typer vizTrigger text='Quotes' />
                        </h3>
                    </div>
                    <div className="col">
                        {
                            props.data && props.data[part] ?
                                <FadeIn>
                                    <>
                                        <div className={styles["quote-container"]}>
                                            <div className={`${styles["quote-content"]} big-text`}>
                                                <div className={styles["quote"]}>
                                                    <q key={String(partIdx)} className={`${styles['q-container']} ${(isDeleting && partIdx) ? ` ${styles['deleting']}` : ''}`}>{text}</q>
                                                    <div className={`${styles['quote-cursor']}${(!isDeleting || partIdx === 0) ? ` ${styles['active']}` : ''}`} />
                                                </div>
                                                <div className={styles["ghosts"]}>
                                                    {quoteGhosts.current}
                                                </div>
                                            </div>
                                            <div className={`${styles['quote-name-title']}${(!hideTitle && !isDeleting) ? ` ${styles['active']}` : ''}`}>
                                                <div className={`${styles['content-ghost-container']} ${styles['name-container']}`}>
                                                    <div className={styles["quote-name"]}>—{props.data[part].Name}<div className={styles["quote-block"]} /></div>
                                                    <div className={styles["ghosts"]}>
                                                        {nameGhosts.current}
                                                    </div>
                                                </div>
                                                <div className={styles['content-ghost-container']}>
                                                    <div className={styles["quote-title"]}>{props.data[part].JobTitle}<div className={styles["quote-block"]} /></div>
                                                    <div className={styles["ghosts"]}>
                                                        {titleGhosts.current}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                </FadeIn>
                                : null
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
)
export default HomeQuotes;