import React from "react";
import styles from './Subscribe.module.scss';
import Splitter from "../../Components/Splitter";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import EmailIcon from "../../Components/assets/email.svg";
import TwitterIcon from "../../Components/assets/logo--twitter.svg";
import LinkedInIcon from "../../Components/assets/logo--linkedin.svg";
import Typer from "../../Components/Typer";
import FadeIn from "../../Components/FadeIn";
import { SectionProps } from "../../@types/Section";
import { validateEmail } from "../../Utils/StringUtil";

interface Props extends SectionProps {
    TwitterURL?: string,
    LinkedInURL?: string,
    SubscribeEndpoint?: string,
    SubscribeParagraph?: string
}

const HomeSubscribe = React.forwardRef<HTMLElement, Props>(function HomeSubscribe(props, ref) {
    const [email, setEmail] = React.useState('');
    const [emailValidated, setEmailValidated] = React.useState(false);

    function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
        const input = e.target as HTMLInputElement;
        setEmailValidated(validateEmail(input.value));
        setEmail(input.value);
    }
    return (
        <section ref={ref}>
            <div className="content-container"><Splitter isThick vizTrigger /></div>
            <div className="content-container padded-left">
                <div className="_2-col-titled">
                    <div className="col">
                        <h3 className="h2 brand-emphasize">
                            <Typer vizTrigger text='Subscribe' />
                        </h3>
                    </div>
                    <div className="col">
                        <FadeIn>
                            <>
                                <div className="_2-col mobile-no-padding-bottom">
                                    <div className="col">
                                        <p className="big-text">
                                            {
                                                props.SubscribeParagraph ? props.SubscribeParagraph : `Stay tuned with latest news, events, and solutions from Kayhan Space via email.`
                                            }
                                        </p>
                                        <form
                                            id="home-subscribe-form"
                                            name="home-subscribe-form"
                                            className={styles["subscribe-form"]}
                                            action={ props.SubscribeEndpoint ? props.SubscribeEndpoint : "https://formspree.io/f/xvolbbaq" }
                                            method="POST"
                                        >
                                            <Input autoComplete="email" name="email" label="Email" onChange={handleOnChange} value={email} placeholder="Type your email here." />
                                            <textarea title="Subscribe text filler" placeholder={"Subscribe"} className={styles["subscribe-text"]} name="message" defaultValue="Subscribe"></textarea>
                                            <Button renderIcon={<EmailIcon />} isSubmit text="Subscribe" disabled={!emailValidated} />
                                        </form>
                                    </div>
                                    <div className="col" >
                                        <div className={`${styles['buttons-wrapper']}`}>
                                            <div className={styles['buttons-container']}>
                                                <div className="col">
                                                    <Button
                                                        label={`Open a link to Kayhan Space's Twitter Account`}
                                                        renderIcon={<TwitterIcon />}
                                                        classNames={styles['subscribe-button']}
                                                        type="secondary"
                                                        isLinkWrapper
                                                        isLink
                                                        openNewTab
                                                        href={props.TwitterURL ? props.TwitterURL : "https://twitter.com/kayhanspace"}
                                                    >
                                                        <p>Twitter<br />@kayhanspace</p>
                                                    </Button>
                                                </div>
                                                <div className="col">
                                                    <Button
                                                        label={`Open a link to Kayhan Space's LinkedIn Account`}
                                                        renderIcon={<LinkedInIcon />}
                                                        classNames={styles['subscribe-button']}
                                                        type="secondary"
                                                        isLinkWrapper
                                                        isLink
                                                        openNewTab
                                                        href={props.LinkedInURL ? props.LinkedInURL : "https://www.linkedin.com/company/kayhan-space/"}
                                                        text="LinkedIn"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </FadeIn>
                        
                    </div>
                </div>
            </div>
    </section>
    )
});
export default HomeSubscribe;