import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import React from 'react';
import { PageSection } from '../@types/PageSection';
import PageNav from '../Components/PageNav';
import HomePerformance from '../PageSections/HomeSections/Performance';
import HomeQuotes from '../PageSections/HomeSections/Quotes';
import HomeProducts from '../PageSections/HomeSections/Products';
import HomeHero from '../PageSections/HomeSections/Hero';
import HomeIntro from '../PageSections/HomeSections/Intro';
import HomeSubscribe from '../PageSections/HomeSections/Subscribe';
import SubscribeSuccessModal from '../Modals/SubscribeSuccess';
import Head from 'next/head';
import { fetchAPI } from '../strapi/api';
import dynamic from 'next/dynamic';
import getConfig from "next/config";
import NewsBanner from '../Components/NewsBanner';
const { publicRuntimeConfig } = getConfig();

const DynamicGame = dynamic(() => import('../Components/Game/game'), {
    ssr: false
})

const Home: NextPage = ({ pageContent }: any) => {
    
    const initialPagesArr = React.useRef(pageContent ? [
        {
            Component: HomeIntro,
            data: {
                Carousel: pageContent.Carousel,
                IntroParagraph: pageContent.IntroParagraph
            },
            title: 'Intro',
            anchorId: 'intro'
        },
        {
            Component: HomeProducts,
            data: pageContent.ProductsList,
            title: 'Products',
            anchorId: 'products'
        },
        {
            Component: HomeQuotes,
            data: pageContent.Quotes,
            title: 'Quotes',
            anchorId: 'quotes'
        },
        {
            Component: HomePerformance,
            data: {
                MetricsParagraph1: pageContent.MetricsParagraph1,
                MetricsParagraph2: pageContent.MetricsParagraph2,
                PerformanceCTATitle: pageContent.PerformanceCTATitle,
                PerformanceCTAUrl: pageContent.PerformanceCTAUrl
            },
            title: 'Metrics',
            anchorId: 'performance'
        }
    ] : []);

    const heroRef = React.createRef<HTMLDivElement>();
    const [showSubscribeModal, setShowSubscribeModal] = React.useState(false);
    const elRefs = React.useRef<React.RefObject<HTMLDivElement>[]>(initialPagesArr.current.map(() => React.createRef()));
    const [activeNo, setActiveNo] = React.useState(0);
    const pageSections = React.useRef<PageSection[]>(
        initialPagesArr.current.map(({ Component, title, anchorId, data }, i) => {
            const newElement = {
                jsx: <Component data={data} key={`section-${i}`} sectionNo={i} ref={elRefs.current[i]} />,
                title,
                anchorId
            }
            return newElement;
        }));

    const { asPath } = useRouter();

    React.useEffect(() => {
        const hash = asPath.split('#')[1];
        setShowSubscribeModal(hash === 'subscribeSuccess');
    }, [asPath]);

    return (
        <>
            {
                pageContent && pageContent.Metadata ?
                    <Head>
                        {pageContent.Metadata.Title ? <title key="title">{pageContent.Metadata.Title}</title> : null}
                        {pageContent.Metadata.Title ? <meta name="twitter:title" content={pageContent.Metadata.Title} key="twname" /> : null}
                        {pageContent.Metadata.Title ? <meta property="og:title" content={pageContent.Metadata.Title} key="ogtitle" /> : null}
                        {pageContent.Metadata.Description ? <meta name="description" content={pageContent.Metadata.Description} key="desc" /> : null}
                        {pageContent.Metadata.Description ? <meta name="og:description" content={pageContent.Metadata.Description} key="ogdesc" /> : null}
                        {pageContent.Metadata.Description ? <meta name="twitter:description" content={pageContent.Metadata.Description} key="twdesc" /> : null}
                        <link rel="canonical" href={publicRuntimeConfig.NEXT_PUBLIC_SITE_URL} key="canonical" />
                    </Head> :
                    <Head>
                        <title key="title">{'404 Page - Kayhan Space'}</title>
                        <meta name="twitter:title" content={'404 Page - Kayhan Space'} key="twname" />
                        <meta property="og:title" content={'404 Page - Kayhan Space'} key="ogtitle" />
                        <meta name="description" content={`Even our astrodynamicists cannot find the page you are looking for.`} key="desc" />
                        <meta name="og:description" content={`Even our astrodynamicists cannot find the page you are looking for.`} key="ogdesc" />
                        <meta name="twitter:description" content={`Even our astrodynamicists cannot find the page you are looking for.`} key="twdesc" />
                        <link rel="canonical" href={`${publicRuntimeConfig.NEXT_PUBLIC_SITE_URL}/error`} key="canonical" />
                    </Head>
            }
            {
                pageContent ?
                    <>
                        {showSubscribeModal ? <SubscribeSuccessModal /> : null}
                        {
                            pageContent.NewsBanner?.LinkTitle &&
                            pageContent.NewsBanner?.LinkDestination ?
                                <NewsBanner
                                    LinkDestination={pageContent.NewsBanner.LinkDestination}
                                    LinkTitle={pageContent.NewsBanner.LinkTitle}
                                /> : null
                        }
                        <main key={`${pageContent.PageType}-${pageContent.slug}`}>
                            <HomeHero
                                HeroParagraph={pageContent.HeroParagraph}
                                ref={heroRef}
                            />
                            <div className="content-wrapper">
                                {
                                    pageSections.current.length > 3 ?
                                        <PageNav
                                            pageSections={pageSections.current}
                                            elRefs={elRefs.current}
                                            activationRef={heroRef}
                                            setActiveNo={setActiveNo}
                                            activeNo={activeNo}
                                        /> : null
                                }
                                <div className="sections-container">
                                    {pageSections.current.map((sectionEl, idx) => {
                                        return <React.Fragment key={`section-${idx}`}>{sectionEl.jsx}</React.Fragment>
                                    })}
                                    <HomeSubscribe
                                        TwitterURL={pageContent.TwitterURL}
                                        LinkedInURL={pageContent.LinkedInURL}
                                        SubscribeEndpoint={pageContent.SubscribeEndpoint}
                                        SubscribeParagraph={pageContent.SubscribeParagraph}
                                    />
                                </div>
                            </div>
                        </main>
                    </> :
                    <DynamicGame />
            }
        </>
    )
};

export async function getServerSideProps() {
    const res = await fetchAPI(`/home-page?populate=deep`);

    if (res && res.data && res.data.attributes && Object.keys(res.data.attributes).length) {
        return {
            props: {
                pageContent: res.data.attributes
            }
        }
    }

    return {
        props: {
            pageContent: null
        }
    }
    
}

export default Home;
