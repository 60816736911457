import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

export function validateEmail(email: string): boolean {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function stringCutter(content: string): string {
    return content.length > 180 ? `${content.slice(0, 177)}...` : content
}

export function getCanonicalArticleUrl(title: string | null | undefined, aid: string | number, type: 'newsroom' | 'blog', generateCanonical?: boolean): string {
    let canonicalUrl = generateCanonical ? `${publicRuntimeConfig.NEXT_PUBLIC_SITE_URL}/${type}` : `/${type}`;
    if (title) {
        canonicalUrl += `/${title.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '')}`;
    }
    canonicalUrl += `/aid/${aid}`;
    canonicalUrl = canonicalUrl.toLowerCase();
    return canonicalUrl
}