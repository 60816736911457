import React from 'react';
import styles from './FadeIn.module.scss';
import VisibilitySensor from 'react-visibility-sensor';

interface Props {
    children: React.ReactElement;
    classNames?: string;
    noTop?: boolean;
}

const FadeIn: React.FC<Props> = (props) => {
    let classNamesInit = styles['fade-in-container'];
    if (props.classNames) classNamesInit += ` ${props.classNames}`;
    if (props.noTop) classNamesInit += ` ${styles['no-top']}`;
    const [classNames, setClassNames] = React.useState(classNamesInit);
    return (
        <VisibilitySensor
            onChange={(isVisible: boolean) => {
                if (classNames.indexOf('active') === -1 && isVisible) setClassNames(`${classNames} ${styles['active']}`);
            }}
            partialVisibility={true}
        >
            <div className={classNames}>
                <div className={styles['fade-in-content']}>{props.children}</div>
            </div>
        </VisibilitySensor>
    )
};

export default FadeIn;