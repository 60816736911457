import React from 'react';
import styles from './Input.module.scss';

interface Props {
    value: string;
    placeholder: string;
    label: string;
    isTextarea?: boolean;
    classNames?: string;
    disabled?: boolean;
    onChange?: Function;
    name?: string;
    kind?: 'text' | 'email' | 'tel'
    autoComplete?: string;
}

const Input: React.FC<Props> = (props) => {
    let classNames = "input-container";
    if (props.classNames) classNames += ` ${props.classNames}`;
    if (props.disabled) classNames += ` ${styles["disabled"]}`;

    const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement> | React.FormEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(e);
    }
    return (
        <label className={classNames}>
            <h4>{props.label}</h4>
            { props.isTextarea ?
                <textarea name={props.name ? props.name : undefined} onChange={handleOnChange} disabled={props.disabled ? true : false} className={styles["text-input"]} value={props.value} placeholder={props.placeholder} />
                : <input autoComplete={props.autoComplete} name={props.name ? props.name : undefined} onChange={handleOnChange} disabled={props.disabled ? true : false} className={styles["text-input"]} type={props.kind ? props.kind : 'text'} value={props.value} placeholder={props.placeholder} />
            }
        </label>
    )
};

export default Input;