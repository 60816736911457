import React from "react";
import { Carousel } from 'react-responsive-carousel';
import { SectionProps } from "../../@types/Section";
import FadeIn from "../../Components/FadeIn";
import TwitterIcon from "../../Components/assets/logo--twitter.svg";
import LinkedInIcon from "../../Components/assets/logo--linkedin.svg";
import InternetIcon from "../../Components/assets/logo--www.svg";
import Image from 'next/image';
import { getStrapiMedia } from "../../strapi/media";

interface Props extends SectionProps {
    data: any
}

const HomeIntro = React.forwardRef<HTMLElement, Props>(function HomeIntro(props, ref) {

    return (
        <section id="intro" ref={ref}>
            <div className="content-container padded-left">
                <div className="_2-col">
                    <div className="col empty"></div>
                    <div className="col">
                        <FadeIn>
                            <div className="h3">
                                {
                                    props.data && props.data.IntroParagraph ? 
                                        props.data.IntroParagraph :
                                        <><h2 className="normalize-text span">Kayhan Space brings next generation autonomy to satellite operations</h2> and ensures spaceflight safety. Our automation removes delays and errors from the critical path of response to high-risk events.</>
                                }
                            </div>
                        </FadeIn>
                    </div>
                </div>
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                >  
                    {props.data && props.data.Carousel && props.data.Carousel.length ?
                        props.data.Carousel.map((c: any, i: number) => {
                            if (c) {
                                const imgSrc = c.Photo ? c.Photo.data ? getStrapiMedia(c.Photo) : '' : '';
                                if (imgSrc && c.Link) {
                                    return (
                                        <div className="carousel-item" key={`home-carousel-${i}`}>
                                            <span className='carousel-image'>
                                                <Image
                                                    src={imgSrc}
                                                    layout='responsive'
                                                    height={c.Photo.data.attributes.height ? c.Photo.data.attributes.height : 578}
                                                    width={c.Photo.data.attributes.width ? c.Photo.data.attributes.width : 1098}
                                                    alt={c.Photo.data.attributes.caption ? c.Photo.data.attributes.caption : "Caption is not available for this photo."}
                                                />
                                            </span>
                                            <a className="legend" href={c.Link} target="_blank" rel="nofollow noopener noreferrer">
                                                <div className="legend-content">
                                                    {
                                                        c.Description ?
                                                            <>
                                                                <span className="h6">{c.Type ? c.Type : 'General'}</span>
                                                                <p>
                                                                    {c.Description}
                                                                </p>
                                                            </>
                                                            : null
                                                    }
                                                </div>
                                                <div className="pseudo-icon-button">
                                                    {
                                                        c.Link.includes('twitter.com') ?
                                                            <TwitterIcon /> :
                                                            c.Link.includes('linkedin.com') ?
                                                                <LinkedInIcon /> :
                                                                <InternetIcon />
                                                    }
                                                </div>
                                            </a>
                                        </div>
                                    )
                                }
                            }
                            return null
                        }) : null
                    }
                </Carousel>
            </div>
        </section>
    )
})
export default HomeIntro;