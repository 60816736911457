import React from "react";
import Link from "./Link"
import styles from './NewsBanner.module.scss';

interface Props {
    LinkTitle?: string | null,
    LinkDestination?: string | null
}

export const NewsBanner = (props: Props) => {
    const [isActive, setIsActive] = React.useState(false);
    return (
        <div className={`${styles['news-banner-container']}`}>
            <Link href={`${props.LinkDestination}`}>
                <div className="content-container">
                    {`${props.LinkTitle}`}
                </div>
            </Link>
        </div>
    )
}

export default NewsBanner