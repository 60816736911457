import React from "react";
import throttle from "lodash/throttle";
import GlobeAnimationWrapper from "./Components/GlobeAnimationWrapper";
import styles from './Hero.module.scss'
import FadeIn from "../../Components/FadeIn";
import { SectionProps } from "../../@types/Section";
import RightArrowIcon from "../../Components/assets/arrow--right.svg";
import Button from "../../Components/Button";

interface Props extends SectionProps {
    HeroParagraph?: string
}

const SHIELD_SIZE = 0.2;

const HomeHero = React.forwardRef<HTMLElement, Props>(function HomeHero(props, ref) {
    const [hasHovered, setHasHovered] = React.useState(false);
    const [width, setWidth] = React.useState(0);
    const [shieldBufferLeftRight, setShieldBufferLeftRight] = React.useState(0);
    const [shieldBufferTopBottom, setShieldBufferTopBottom] = React.useState(0);
    //const [scrollAnimLeftPos, setScrollAnimLeftPos] = useState(0);
    const sectionRef = React.useRef<HTMLDivElement>(null);
    const [show3d, setShow3d] = React.useState(false);

    const handleMouseEnter = throttle(() => { setHasHovered(true); }, 300);
    
    React.useEffect(() => {
        const handleResize = throttle(() => {
            setShow3d(true);
            if (sectionRef !== null && sectionRef.current !== null) {
                let squaredResolutionValue = 0;
                if (sectionRef.current.offsetWidth > sectionRef.current.offsetHeight) {
                    squaredResolutionValue = sectionRef.current.offsetHeight;
                } else {
                    squaredResolutionValue = sectionRef.current.offsetWidth;
                }
                const shieldBufferTopBottom = Math.ceil(squaredResolutionValue * SHIELD_SIZE);
                setWidth(squaredResolutionValue);
                setShieldBufferLeftRight(sectionRef.current.offsetHeight - (shieldBufferTopBottom * 2));
                setShieldBufferTopBottom(shieldBufferTopBottom);
                //48 is half of 96px (width of the svg)
                //setScrollAnimLeftPos((squaredResolutionValue / 2) - 48);
            }
        }, 300);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section ref={ref} className="layer-1-bg" id="hero">
            <div className={`content-container ${styles['home-hero']}`} ref={sectionRef}>
                <div className="_x-col-container">
                    <div className="_2-col hero">
                        <div className="col">
                            <div className={styles['hero-content-container']}>
                                <FadeIn>
                                    <h1 className="hero-slogan">Making <br />Spaceflight <br /><span className="tm">Safer</span></h1>
                                </FadeIn>
                                <FadeIn>
                                    <>
                                        <p className="big-text description">
                                            <span className={styles['hero-text-background']}>
                                                {
                                                    props.HeroParagraph ?
                                                    props.HeroParagraph :
                                                    `Kayhan Space is on a mission to bring next-generation automation to satellite operations and spaceflight safety. We are a leader in delivering autonomous safety of flight alerts, notifications, and decision plans directly to satellite operators.`
                                                }
                                            </span>
                                        </p>
                                        <Button isLink text="Explore products" href='/products/' renderIcon={<RightArrowIcon />}></Button>
                                    </>
                                </FadeIn>
                            </div>
                        </div>
                    </div>
                    <div className={styles["animation-container"]}>
                        <div className={styles["shield-container"]} style={{ width: width }}>
                            <div className={styles["interaction-shield"]} onMouseEnter={() => !hasHovered ? handleMouseEnter() : undefined} style={{ width: width, height: width, display: !hasHovered ? 'block' : 'none' }}>
                                <svg className={styles["scroll-interaction-icon"]} role="img" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 38" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                                    <g className={styles["touch-icon-container"]} transform="translate(7.748154,30.000004)">
                                        <path className="touchIcon" d="M9,26h1c0-.9283-.36875-1.8185-1.02513-2.4749s-1.54661-1.0251-2.47487-1.0251-1.8185.3687-2.47487,1.0251-1.02513,1.5466-1.02513,2.4749h1c0-.663.26339-1.2989.73223-1.7678C5.20107,23.7634,5.83696,23.5,6.5,23.5s1.29893.2634,1.76777.7322C8.73661,24.7011,9,25.337,9,26Zm2.765,3.7c.2232-.1301.4767-.1991.735-.2.3978,0,.7794.158,1.0607.4393s.4393.6629.4393,1.0607v3c0,.9283-.3687,1.8185-1.0251,2.4749s-1.5466,1.0251-2.4749,1.0251h-2.49999c-.76.0015-1.4937-.2781-2.06-.785l-4-3.65c-.21123-.2095-.3554-.4771-.41416-.7687-.05875-.2917-.02946-.5942.08417-.8692s.30645-.5099.55396-.675.53852-.2528.83603-.2521c.31869-.0022.6294.0996.885.29l1.115.76v-5.55c0-.3978.15804-.7794.43934-1.0607s.66284-.4393,1.06066-.4393.77936.158,1.06066.4393.43934.6629.43934,1.0607v2.59c.31983-.1128.66846-.1139.989-.0032s.59409.327.776.6132c.22317-.1301.47669-.1991.73499-.2.2528.0007.5013.0652.7224.1875s.4078.2987.5426.5125Zm.5028,6.0678C12.7366,35.2989,13,34.663,13,34v-3c0-.1326-.0527-.2598-.1464-.3536-.0938-.0937-.221-.1464-.3536-.1464s-.2598.0527-.3535.1464C12.0527,30.7402,12,30.8674,12,31v.5h-1v-1c0-.1326-.0527-.2598-.1464-.3536C10.7598,30.0527,10.6326,30,10.5,30s-.2598.0527-.3535.1464c-.0938.0938-.1465.221-.1465.3536v1h-.99999v-1.5c0-.1326-.05268-.2598-.14645-.3536-.09376-.0937-.22094-.1464-.35355-.1464s-.25979.0527-.35355.1464c-.09377.0938-.14645.221-.14645.3536v1.5h-1v-5.5c0-.1326-.05268-.2598-.14645-.3536-.09376-.0937-.22094-.1464-.35355-.1464s-.25979.0527-.35355.1464c-.09377.0938-.14645.221-.14645.3536v7.45l-2.7-1.85c-.07428-.0557-.16262-.0896-.2551-.098-.09248-.0083-.18545.0093-.26851.0508s-.1529.1053-.20172.1843-.07467.17-.07467.2629c.00055.1312.05263.2569.145.35L6.62001,36c.38419.3284.8746.5061,1.38.5h2.49999c.6631,0,1.2989-.2634,1.7678-.7322Z" transform="translate(-7.748154,-30.000004)" clipRule="evenodd" fillRule="evenodd" />
                                    </g>
                                    <g className={styles["mouse-icon-container"]} transform="translate(8.5,8)">
                                        <g className="mouseIcon" transform="translate(-8.5,-8)">
                                            <path className={styles["mouse-icon-click"]} d="M4,5C4,2.79086,5.79086,1,8,1v0v8h-4v-4Z" />
                                            <path d="M4,5C4,2.79086,5.79086,1,8,1v2h-1v1v2v1h1v2h1v-2h1v-1-2-1h-1v-2c2.2091,0,4,1.79086,4,4v6c0,2.2091-1.7909,4-4,4h-1c-2.20914,0-4-1.7909-4-4v-6ZM3,5C3,2.23858,5.23858,0,8,0h1c2.7614,0,5,2.23858,5,5v6c0,2.7614-2.2386,5-5,5h-1c-2.76142,0-5-2.2386-5-5v-6ZM8,4h1v2h-1v-2Z" clipRule="evenodd" fillRule="evenodd" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            
                            <div className={`${styles["scroll-shield"]} ${styles["scroll-shield-top"]}`} style={{ height: shieldBufferTopBottom, width: width, top: -width }} />
                            <div className={`${styles["scroll-shield"]} ${styles["scroll-shield-right"]}`} style={{ height: shieldBufferLeftRight, width: shieldBufferTopBottom, top: -(shieldBufferLeftRight + shieldBufferTopBottom) }} />
                            <div className={`${styles["scroll-shield"]} ${styles["scroll-shield-bottom"]}`} style={{ height: shieldBufferTopBottom, width: width }} />
                            <div className={`${styles["scroll-shield"]} ${styles["scroll-shield-left"]}`} style={{ height: shieldBufferLeftRight, width: shieldBufferTopBottom, top: -(shieldBufferLeftRight + shieldBufferTopBottom) }} />
                        </div>
                        {show3d ? <GlobeAnimationWrapper height={width} width={width} /> : null}
                    </div>
                </div>
            </div>
        </section>
    )
})
export default HomeHero;