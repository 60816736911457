import React from "react";
import { SectionProps } from "../../@types/Section";
import Button from "../../Components/Button";
import Splitter from "../../Components/Splitter";
import Typer from "../../Components/Typer";
import FadeIn from "../../Components/FadeIn";
import PerformanceCircle from "../../Components/assets/performance-circle.svg";
import RightArrowIcon from "../../Components/assets/arrow--right.svg";
import styles from "./Performance.module.scss";
import VisibilitySensor from 'react-visibility-sensor';

interface Props extends SectionProps {
    data: any
}

function nFormatter(num: number, digits: number): string {
    if (num) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        const item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }
    return '—'
}

const epoch = 1648758747716;
const today = new Date().getTime();
const hoursDifference = Math.ceil((today - epoch) / 3600000);
const metric1Base = 30000;
const metric2Base = Math.ceil(282157 + ((2297 / 24) * hoursDifference));
const metric3Base = Math.ceil(730 + ((7 / 24) * hoursDifference));
const metric4Base = Math.ceil((7 * 30) * 2);

const frameTime = 30;

const HomePerformance = React.forwardRef<HTMLElement, Props>(function HomePerformance(props, ref) {

    const intervalId1: { current: NodeJS.Timeout | null } = React.useRef(null);
    const hasRunOnce = React.useRef(false);
    const [activated, setActivated] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false);
    const [metricsObj, setMetricsObj] = React.useState({
        completeCounter: 0,
        metrics: [
            { frameTime: Math.floor(metric1Base / frameTime), base: metric1Base, current: 0 },
            { frameTime: Math.floor(metric2Base / frameTime), base: metric2Base, current: 0 },
            { frameTime: Math.floor(metric3Base / frameTime), base: metric3Base, current: 0 },
            { frameTime: Math.floor(metric4Base / frameTime), base: metric4Base, current: 0 },
        ]
    });

    const addMetrics = React.useCallback(() => {
        const newMetricsObj = { ...metricsObj };
        newMetricsObj.metrics = metricsObj.metrics.map((metric) => {
            if (metric.current < metric.base) {
                metric.current += metric.frameTime;
            } else {
                newMetricsObj.completeCounter += 1;
            }
            return metric
        });
        if (newMetricsObj.completeCounter >= newMetricsObj.metrics.length) hasRunOnce.current = true;
        setMetricsObj(newMetricsObj);
    }, [metricsObj]);

    React.useEffect(() => {
        if (isVisible && !activated) setActivated(true);
        if (activated && !hasRunOnce.current) {
            const timeoutSession1 = setTimeout(() => {
                addMetrics();
            }, frameTime);
            intervalId1.current = timeoutSession1;
        }
    }, [addMetrics, metricsObj, isVisible, activated]);

    React.useEffect(() => {
        return () => {
            if (intervalId1.current) clearTimeout(intervalId1.current as NodeJS.Timeout);
        }
    }, []);
    
    return (
        <section id="performance" ref={ref}>
            <div className="content-container">
                <Splitter vizTrigger isThick text={String(props.sectionNo).padStart(2, '0')} />
            </div>
            <div className="content-container padded-left">
                <div className="_2-col-titled">
                    <div className="col">
                        <h3 className="h2">
                            <Typer vizTrigger text='Metrics' />
                        </h3>
                    </div>
                    <div className="col">
                        <FadeIn>
                            <>
                                <div className='_2-col'>
                                    <div className="col">
                                        <p className="big-text">
                                            {
                                                props.data && props.data.MetricsParagraph1 ?
                                                props.data.MetricsParagraph1 :
                                                `Our highly-available and reliable automated solutions are designed to help satellite operators focus on their core missions, without having to worry about the safety of their fleet.`
                                            }
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p className="big-text">
                                            {
                                                props.data && props.data.MetricsParagraph2 ?
                                                    props.data.MetricsParagraph2 :
                                                    `We accomplish this by removing delays and errors from the critical response path. This saves time and money for our customers by reducing the response time by more than 95%, optimizing the avoidance maneuvers with the mission critical timelines, minimizing the need for unnecessary maneuvers, and improving the mission output.`
                                            }
                                        </p>
                                    </div>
                                </div>
                                <Splitter vizTrigger />
                                
                                    <div className={`${styles['performance-container']} _2-col ${activated ? ` ${styles['active']}` : ''}`}>
                                        <div className={`col`}>
                                            <div className={styles["metrics-column"]}>
                                                <div>
                                                    <div className={styles["metric-number"]}>{nFormatter(metricsObj.metrics[0].current, 0)}</div>
                                                    <p>Objects tracked</p>
                                                </div>
                                                <div>
                                                    <div className={styles["metric-number"]}>{nFormatter(metricsObj.metrics[1].current, 0)}</div>
                                                    <p>Warnings delivered</p>
                                                </div>
                                                <div>
                                                    <div className={styles["metric-number"]}>{nFormatter(metricsObj.metrics[2].current, 0)}</div>
                                                    <p>Maneuvers generated</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col`}>
                                            <div className={styles["metrics-bubble-column"]}>
                                                <div className={styles["metric-bubble-container"]}>
                                                    <PerformanceCircle />
                                                    <div className={styles["metric-container"]}>
                                                        <VisibilitySensor onChange={(isVisible: boolean) => { if (!hasRunOnce.current && isVisible) setIsVisible(true) }}>
                                                            <div className={styles["metric-number"]}>{nFormatter(metricsObj.metrics[3].current, 2)}</div>
                                                        </VisibilitySensor>
                                                        <p>Operator hours<br />saved per month</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                <Splitter vizTrigger />
                                <div className="_2-col">
                                    <div className="col mobile-no-padding-bottom">
                                        {
                                            props.data && props.data.PerformanceCTATitle && props.data.PerformanceCTAUrl ?
                                                <Button
                                                    renderIcon={<RightArrowIcon />}
                                                    isLink
                                                    href={props.data.PerformanceCTAUrl}
                                                    text={props.data.PerformanceCTATitle}
                                                /> : null
                                        }
                                    </div>
                                    <div className="col empty" />
                                </div>
                            </>
                        </FadeIn>
                    </div>
                </div>
            </div>
        </section>
    )
}
)
export default HomePerformance;